import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

let routes = [
  {
    path: "/",
    redirect: "/home",
    meta: {
      noNeedLogin: true,
    },
  },

  // 首页
  // {
  //   path: "/home",
  //   component: Home,
  //   name: "home",
  //   meta: {
  //     noNeedLogin: true,
  //   },
  // },
  // 数据大屏 alarm count
  {
    path: "/datascreen",
    component: () => import("@/views/BigScreen.vue"),
    name: "datascreen",
  },

  // 登录
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      noNeedLogin: true,
    },
  },

  // 忘记密码
  {
    path: "/forget",
    name: "forget",
    component: () => import("@/views/ForgetPwd.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  // 注册
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/Register.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  // 我的设备
  // {
  //   path: "/device",
  //   name: "device",
  //   component: () => import("@/views/Device.vue"),
  // },
  // 技术中心
  {
    path: "/tecnology",
    name: "tecnology",
    component: () => import("@/views/Tecnology.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  // 二手机床
  {
    path: "/shopping",
    name: "shopping",
    component: () => import("@/views/Shopping.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  // 配件商城
  {
    path: "/market",
    name: "market",
    component: () => import("@/views/Market.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  //配件商城列表
  {
    path: "/market-list",
    name: "marketlist",
    component: () => import("@/views/MarketList.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  //配件商城结算
  {
    path: "/market-settle",
    name: "marketsettle",
    component: () => import("@/views/MarketSettle.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  //配件商城结算成功
  {
    path: "/market-add-order",
    name: "marketaddorder",
    component: () => import("@/views/MarketAddOrder.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  //配件商品详情
  {
    path: "/marketdetail",
    name: "marketdetail",
    component: () => import("@/views/MarketDetail.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  //商品列表
  {
    path: "/shopping-list",
    name: "shoppinglist",
    component: () => import("@/views/ShoppingList.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  //  意向书
  {
    path: "/intention",
    name: "intention",
    component: () => import("@/views/Intention.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  //商品详情
  {
    path: "/detail",
    name: "detail",
    component: () => import("@/views/Detail.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  // 案例详情
  {
    path: "/case-detail",
    name: "case-detail",
    component: () => import("@/views/my-case-detail/Case-Detail.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  // 案例文章视频详情
  {
    path: "/case-video",
    name: "case-video",
    component: () => import("@/views/my-case-detail/Case-Video.vue"),
    meta: {
      noNeedLogin: true,
    },
  },

  // 报修
  {
    path: "/nologinrepair",
    name: "nologinrepair",
    component: () => import("@/views/NoLoginRepair.vue"),
    meta: { noNeedLogin: true },
  },
  // 关于我们
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/About.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  // 隐私政策
  {
    path: "/secret",
    name: "secret",
    component: () => import("@/views/Secret.vue"),
    meta: {
      noNeedLogin: true,
    },
  },
  // 意见反馈
  // {
  //   path: "/feedback",
  //   name: "feedback",
  //   component: () => import("@/views/Feedback.vue"),
  // },
  // 联系我们
  {
    path: "/contact",
    name: "contact",
    redirect: "/contact/personal",
    component: () => import("@/views/Contact.vue"),
    children: [
      // 个人中心
      {
        path: "personal",
        component: () => import("@/components/my/Personal.vue"),
      },
      // 我的订单
      // {
      //   path: "order",
      //   component: () => import("@/components/my/Order.vue"),
      // },
      // 保养计划
      // {
      //   path: "serviceplan",
      //   component: () => import("@/components/my/ServicePlan.vue"),
      // },
      // 保养标准
      // {
      //   path: "servicelevel",
      //   component: () => import("@/components/my/ServiceLevel.vue"),
      // },
      // 车间管理
      // {
      //   path: "workshoprun",
      //   component: () => import("@/components/my/WorkShopRun.vue"),
      // },
      // 我的地址
      // {
      //   path: "address",
      //   component: () => import("@/components/my/Address.vue"),
      // },
      // 我的收藏
      {
        path: "collect",
        // redirect: '/collect/content',
        component: () => import("@/components/my/Collect.vue"),
        children: [
          {
            path: "",
            component: () => import("@/views/my-center/Content.vue"),
          },
          {
            path: "content",
            name: "collect-content",
            component: () => import("@/views/my-center/Content.vue"),
          },
          {
            path: "edit",
            name: "collect-edit",
            component: () => import("@/views/my-center/Edit.vue"),
          },
        ],
      },
      // 意向记录
      // {
      //   path: "record",
      //   component: () => import("@/components/my/Record.vue"),
      // },
      // 所属公司
      {
        path: "company",
        component: () => import("@/components/my/Company.vue"),
      },
      // 联系我们
      // {
      //   path: "connect",
      //   name: "connect",
      //   component: () => import("@/components/my/Connect.vue"),
      // },
      // 系统消息
      // {
      //   path: "info",
      //   component: () => import("@/components/my/Info.vue"),
      // },
      // 系统设置
      {
        path: "set",
        component: () => import("@/components/my/Set.vue"),
        children: [
          {
            path: "",
            component: () => import("@/views/my-center/SystemSet.vue"),
          },
          {
            path: "verify-pwd",
            name: "verify-pwd",
            component: () => import("@/views/my-center/VerifyPwd.vue"),
          },
          {
            path: "revise-comp",
            name: "revise-comp",
            component: () => import("@/views/my-center/ReviseComp.vue"),
          },
          {
            path: "verify-phone",
            name: "verify-phone",
            component: () => import("@/views/my-center/VerifyPhone.vue"),
          },
          {
            path: "bind-new-phone",
            name: "bind-new-phone",
            component: () => import("@/views/my-center/BindNewPhone.vue"),
          },
        ],
      },
    ],
  },
  // 评论
  {
    path: "/comment",
    name: "comment",
    component: () => import("@/views/my-center/Comment.vue"),
  },
  // 添加设备
  // {
  //   path: "/adddevice",
  //   name: "adddevice",
  //   component: () => import("@/views/Adddevice.vue"),
  // },
  // 编辑设备
  // {
  //   path: "/editdevice",
  //   name: "editdevice",
  //   component: () => import("@/views/EditDevice.vue"),
  // },
  // 报修报表
  // {
  //   path: "/repairform",
  //   name: "repairform",
  //   component: () => import("@/views/Repairform.vue"),
  // },
  // 维保记录
  // {
  //   path: "/repairrecord",
  //   name: "repairrecord",
  //   component: () => import("@/views/Repairrecord.vue"),
  // },
  // 新增维保记录
  // {
  //   path: "/add-repair-record",
  //   name: "add-repair-record",
  //   component: () => import("@/views/my-device/Add-Repair-Record.vue"),
  // },
  // 新增保养计划
  {
    path: "/add-service-plan",
    name: "add-service-plan",
    component: () => import("@/views/my-center/AddServicePlan.vue"),
  },
  // 新增保养标准
  {
    path: "/add-service-level",
    name: "add-service-level",
    component: () => import("@/views/my-center/AddServiceLevel.vue"),
  },
  // 维保提醒
  // {
  //   path: "/repairremind",
  //   name: "repairremind",
  //   component: () => import("@/views/Repairremind.vue"),
  // },
  // 添加预设时间
  // {
  //   path: "/add-present-time",
  //   name: "add-present-time",
  //   component: () => import("@/views/my-device/Add-Rresent-Time.vue"),
  // },
  // 编辑预设时间
  // {
  //   path: "/edit-device-remind",
  //   name: "edit-device-remind",
  //   component: () => import("@/views/my-device/Edit-Device-Remind.vue"),
  // },
  // 设备资料
  // {
  //   path: "/adddata",
  //   name: "adddata",
  //   component: () => import("@/views/Adddata.vue"),
  // },
  // 故障分析
  // {
  //   path: "/faultasync",
  //   name: "faultasync",
  //   component: () => import("@/views/FaultAsync.vue"),
  // },
  // 添加设备资料
  // {
  //   path: "/add-device-data",
  //   name: "add-device-data",
  //   component: () => import("@/views/my-device/Add-Device-Data.vue"),
  // },
  //  添加位置
  // {
  //   path: "/addpos",
  //   name: "addpos",
  //   component: () => import("@/views/AddPos.vue"),
  // },
  //  编辑位置
  {
    path: "/editpos",
    name: "editpos",
    component: () => import("@/views/EditPos.vue"),
  },
  //  订单详情
  {
    path: "/orderdetail",
    name: "orderdetail",
    component: () => import("@/views/OrderDetail.vue"),
  },
  //  查看摄像头
  {
    path: "/seecamera",
    name: "seecamera",
    component: () => import("@/views/SeeCamera.vue"),
  },
];

routes = routes.concat([
  {
    path: "",
    component: () => import("@/Layout/index.vue"),
    children: [
      {
        path: "/index",
        component: () => import("@/views/cheng/big_Screen.vue"),
      },
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/cheng/big_Screen.vue"),
      },
      {
        path: "/device",
        name: "device",
        component: () => import("@/views/cheng/devices/device.vue"),
      },
      {
        path: "/add_device",
        name: "add_device",
        component: () => import("@/views/cheng/devices/add_device.vue"),
      },
      {
        path: "/repairform",
        name: "repairform",
        component: () => import("@/views/Repairform.vue"),
      },
      {
        path: "/addpos",
        name: "addpos",
        component: () => import("@/views/AddPos.vue"),
      },
      {
        path: "/repairrecord",
        name: "repairrecord",
        component: () => import("@/views/Repairrecord.vue"),
      },
      {
        path: "/add-repair-record",
        name: "add-repair-record",
        component: () => import("@/views/my-device/Add-Repair-Record.vue"),
      },
      {
        path: "/repairremind",
        name: "repairremind",
        component: () => import("@/views/Repairremind.vue"),
      },
      {
        path: "/add-present-time",
        name: "add-present-time",
        component: () => import("@/views/my-device/Add-Rresent-Time.vue"),
      },
      {
        path: "/edit-device-remind",
        name: "edit-device-remind",
        component: () => import("@/views/my-device/Edit-Device-Remind.vue"),
      },
      {
        path: "/adddata",
        name: "adddata",
        component: () => import("@/views/Adddata.vue"),
      },
      {
        path: "/add-device-data",
        name: "add-device-data",
        component: () => import("@/views/my-device/Add-Device-Data.vue"),
      },
      {
        path: "/editdevice",
        name: "editdevice",
        component: () => import("@/views/EditDevice.vue"),
      },
      {
        path: "/faultasync",
        name: "faultasync",
        component: () => import("@/views/FaultAsync.vue"),
      },{
        path: "/contact/order",
        component: () => import("@/components/my/Order.vue"),
      },
      {
        path: "/contact/address",
        component: () => import("@/components/my/Address.vue"),
      },
      {
        path: "/contact/workshoprun",
        component: () => import("@/components/my/WorkShopRun.vue"),
      },
      {
        path: "/contact/connect",
        name: "connect",
        component: () => import("@/components/my/Connect.vue"),
      },
      {
        path: "/feedback",
        name: "feedback",
        component: () => import("@/views/Feedback.vue"),
      },
      {
        path: "/contact/servicelevel",
        component: () => import("@/components/my/ServiceLevel.vue"),
      },
      {
        path: "/contact/record",
        component: () => import("@/components/my/Record.vue"),
      },
      {
        path: "/contact/info",
        component: () => import("@/components/my/Info.vue"),
      },
      {
        path: "/contact/serviceplan",
        component: () => import("@/components/my/ServicePlan.vue"),
      },{
        path: "*",
        redirect: "/index",
      }
    ]
  }
])

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 切换路由后的页面滚动条总保持在顶部
    return { x: 0, y: 0 };
  },
});

export default router;
