import Vue from "vue";

Vue.mixin({
  data() {
    return {
    };
  },
  methods: {
    
  },
  created() {
    localStorage.setItem("scrollPagePath", this.$route && this.$route.path);
  },
});
