"use strict";
import myHttp from "./myAxios";
import store from "../store";

import { post, put } from "./myAxios";

/**
 *
 * @param {options} options 参数集合
 * @param {url}    url *: 地址
 */
const API = {
  getDeviceDataCount: (params) => {
    return myHttp({
      url: "/device/device/getDataCount",
      method: "GET",
      params,
    });
  },
  getIotDeviceUsageRate: (params) => {
    return myHttp({
      url: "/data/screen/getIotDeviceUsageRate",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  // 二开
  //注册
  toRegist: (params) => {
    return myHttp({
      url: "/user/register",
      method: "POST",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //报警记录
  iotPoliceList: (params) => {
    return myHttp({
      url: "/iotWeimingRecord/iotWeimingRecord/list",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //iot获取最新信息
  iotNewData: (params) => {
    return myHttp({
      url: "/iotWeiming/iotWeiming/getNewInfo",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //iot获取图表
  iotDataChart: (params) => {
    return myHttp({
      url: "/iotWeimingBase/iotWeimingBase/list",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //iot获取图表
  iotDataChart1: (params) => {
    return myHttp({
      url: "/iotWeimingLoad/iotWeimingLoad/list",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //iot获取图表报警记录
  iotDataAlarm: (params) => {
    return myHttp({
      url: "/iotWeimingAlarm/iotWeimingAlarm/list",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //iot获取图表nc
  iotDataNC: (params) => {
    return myHttp({
      url: "/iotWeimingLoad/iotWeimingLoad/getCount",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //iot预维报警总览查询接口
  iotDataPreAlarm: (params) => {
    return myHttp({
      url: "/device/device/getSwsAlarmInfoPageList",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //iotAWS预维报警列表
  iotDataPreList: (params) => {
    return myHttp({
      url: "/device/device/queryPMAlarmList",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //吉兰丁统计图f
  iotGetDataScreen: (params) => {
    return myHttp({
      url: "/device/device/getDataScreen",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //iotAWS预维报警列表处理接口
  iotDataPreDeal: (params) => {
    return myHttp({
      url: "/device/device/dealPMAlarm",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //iotAWS碰撞缓冲报警卡面页总览
  iotAlarmOverview: (params) => {
    return myHttp({
      url: "/device/device/queryCollisionCacheAlarmOverview",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //iot碰撞缓存报警列表
  iotAlarmCacheAlarmList: (params) => {
    return myHttp({
      url: "/device/device/queryCollisionCacheAlarmList",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //iot预维通道下的特征值
  iotHistoryList: (params) => {
    return myHttp({
      url: "/device/device/getAwsCollideAlarmInfoCount",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //iotAWS碰撞缓冲报警
  iotAlarmInFoDetail: (params) => {
    return myHttp({
      url: "/device/device/queryCollisionCacheAlarmInFo",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //月度统计
  iotGetAlarmMonthCountFun: (params) => {
    return myHttp({
      url: "/device/device/getAlarmMonthCount",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //设备订单列表
  faultList: (params) => {
    return myHttp({
      url: "/data/screen/getDeviceOrderList",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //设备故障部位统计
  BreakdownCount: (params) => {
    return myHttp({
      url: "/data/screen/getDeviceBreakdown",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //获取设备维修频率
  BreakdownFrequency: (params) => {
    return myHttp({
      url: "/data/screen/getMaintainFrequency",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //周度统计
  iotGetAlarmWeekCountFun: (params) => {
    return myHttp({
      url: "/device/device/getAlarmWeekCount",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //轴列表
  iotGetZhouList: (params) => {
    return myHttp({
      url: "/deviceAxis/deviceAxis/list",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //自研传感器统计表-分页列表查询
  iotResearchCount: (params) => {
    return myHttp({
      url: "/iotResearchCount/iotResearchCount/list",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //自研传感器数据表-分页列表查询
  iotResearchFun: (params) => {
    return myHttp({
      url: "/iotResearch/iotResearch/list",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //获取设备维修频率
  iotGetMaintainFrequency: (params) => {
    return myHttp({
      url: "/deviceMaintenance/deviceMaintenance/getList",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //数据大屏获取报警记录
  screenGetAlarm: (params) => {
    return myHttp({
      url: "/data/screen/getAlaram",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //获取设备维修率列表
  screenDeviceMaintenance: (params) => {
    return myHttp({
      url: "/deviceMaintenance/deviceMaintenance/getList",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //数据大屏数量统计
  screenGetNum: (params) => {
    return myHttp({
      url: "/data/screen/getDeviceNum",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //获取设备状态
  screenGetDeviceStatus: (params) => {
    return myHttp({
      url: "/data/screen/getDeviceStatus",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //数据大屏进行中订单
  screenGetDoingOrder: (params) => {
    return myHttp({
      url: "/data/screen/getOrderList",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //数据大屏保养计划列表
  screenGetBaoList: (params) => {
    return myHttp({
      url: "/data/screen/getDeviceMaintain",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //数据大屏iot设备使用率
  screenGetUseRate: (params) => {
    return myHttp({
      url: "/data/screen/getIotUsageRate",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //数据大屏iot碰撞缓冲报警
  screenGetHitAlarm: (params) => {
    return myHttp({
      url: "/device/device/queryCollisionCacheAlarmInFo",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  // ================ 系统配置 ================
  //根据分组查询系统配置
  getConfig: (params) => {
    return myHttp({
      url: "/config/config/queryByConfigGroup",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //配件商城
  peiJian: (params) => {
    return myHttp({
      url: "/goods/secondGoods/shopUrl",
      method: "GET",
    });
  },
  // ================ oss ================
  //获取oss上传所需数据
  getOssConfig: (params) => {
    return myHttp({
      url: "/common/getSignature",
      method: "GET",
    });
  },
  //上传base64图片到阿里云OSS
  senMorePic: (params) => {
    return myHttp({
      url: "/common/fileUploadBase64Ali",
      method: "POST",
      params,
    });
  },
  // ================ 账号密码 ================
  //账号密码登录
  login: (params) => {
    return myHttp({
      url: "/user/login",
      method: "POST",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //根据token获取个人信息
  getInfo: (params) => {
    return myHttp({
      url: "/user/getUserByToken",
      method: "GET",
    });
  },
  //查询登录用户所属公司
  getUserComp: (params) => {
    return myHttp({
      url: "/device/device/companyList",
      method: "GET",
    });
  },
  //编辑资料
  editMsg: (params) => {
    return myHttp({
      url: "/user/improveInformation",
      method: "POST",
      params,
    });
  },
  //修改手机号
  changePhone: (params) => {
    return myHttp({
      url: "/user/updateMobile",
      method: "POST",
      data: params,
    });
  },
  // ================ 个人中心 ================
  //退出登录
  outLogin: (params) => {
    return myHttp({
      url: "/user/logout",
      method: "POST",
    });
  },
  //修改密码
  changePwd: (params) => {
    return myHttp({
      url: "/user/updatePwd",
      method: "POST",
      data: params,
    });
  },
  //账号密码登录
  toIntent: (params) => {
    return myHttp({
      url: "/repair/repairIntention/add",
      method: "POST",
      data: params,
    });
  },
  //获取验证码
  getCode: (params) => {
    return myHttp({
      url: "/sms/aliSend",
      method: "POST",
      params,
    });
  },
  //检验短信验证码
  checkCode: (params) => {
    return myHttp({
      url: "/sms/checkSend",
      method: "GET",
      params,
    });
  },
  //忘记密码
  forgetPwd: (params) => {
    return myHttp({
      url: "/user/forgetPwd",
      method: "POST",
      params,
    });
  },
  // ================ 平台公告 系统消息 ================
  //查询平台公告
  getPlantMsg: (params) => {
    return myHttp({
      url: "/message/message/announcementList",
      method: "GET",
      params,
    });
  },
  //系统消息-分页列表查询
  getSysMsg: (params) => {
    return myHttp({
      url: "/message/message/list",
      method: "GET",
      params,
    });
  },
  // ================ 我的设备 ================
  //查询省市区
  getArea: (params) => {
    return myHttp({
      url: "/area/area/areaTree",
      method: "GET",
    });
  },
  // 查询所有公司
  getAllComp: (params) => {
    return myHttp({
      url: "/device/device/companyList",
      method: "GET",
    });
  },
  // 状态分类统计
  getStatusNum: (params) => {
    return myHttp({
      url: "/device/device/countByStatus",
      method: "GET",
    });
  },
  // /common/getDictItems/device_status
  // 所有设备状态
  getAllState: (params) => {
    return myHttp({
      url: "/common/getDictItems/device_status",
      method: "GET",
    });
  },
  // /common/getDictItems/device_status
  // 我的订单设备状态
  getAllOrderState: (params) => {
    return myHttp({
      url: "/common/getDictItems/order_status",
      method: "GET",
    });
  },
  // 设备控制系统-全部列表查询
  getAllControl: (params) => {
    return myHttp({
      url: "/device/deviceControl/listAll",
      method: "GET",
    });
  },
  // 分页列表查询
  getAllList: (params) => {
    return myHttp({
      url: "/device/device/list",
      method: "GET",
      params,
    });
  },
  // 通过id查询
  getDeviceRes: (params) => {
    return myHttp({
      url: "/device/device/queryById",
      method: "GET",
      params,
    });
  },
  // 查询全部设备类型
  getAllType: (params) => {
    return myHttp({
      url: "/device/deviceType/deviceTypeList",
      method: "GET",
    });
  },
  // 查询全部设备品牌
  getAllBrand: (params) => {
    return myHttp({
      url: "/device/deviceBrand/listAll",
      method: "GET",
    });
  },
  // 查询全部地址管理
  getAllAdd: (params) => {
    return myHttp({
      url: "/repair/address/list",
      method: "GET",
      params,
    });
  },
  // 设备-编辑
  editAdd: (params) => {
    return myHttp({
      url: "/device/device/edit",
      method: "POST",
      data: params,
    });
  },
  // 设备-添加
  addAdd: (params) => {
    return myHttp({
      url: "/device/device/add",
      method: "POST",
      data: params,
    });
  },
  // 设备-导出设备
  exportAdd: (params) => {
    return myHttp({
      url: "/device/device/exportXls",
      method: "GET",
      headers: {
        responseType: "blob",
      },
      params,
    });
  },
  // 我的订单-导出订单
  exportOrder: (params) => {
    return myHttp({
      url: "/device/device/exportXls",
      method: "GET",
      params,
    });
  },
  // 设备-获取系统编号
  getAddSys: (params) => {
    return myHttp({
      url: "/device/device/getSysNum",
      method: "GET",
    });
  },

  // ================ 首页 ================
  // 文章-分页列表查询
  getArticeListApi: (params) => {
    return myHttp({
      url: "/goods/essay/list",
      method: "GET",
      params,
    });
  },

  // ================ 技术中心  文章 ================
  // 文章-通过id查询
  getArticeByID: (params) => {
    return myHttp({
      url: "/goods/essay/queryById",
      method: "GET",
      params,
    });
  },
  // 通过文章id查询附件
  getArticeFile: (params) => {
    return myHttp({
      url: "/goods/essay/fileListByEssayId",
      method: "GET",
      params,
    });
  },

  // ================ 收藏 ================
  // 用户收藏-添加
  addCollection: (params) => {
    return myHttp({
      url: "/user/userCollection/add",
      method: "POST",
      data: params,
    });
  },

  // ================ 二手商品 ================
  // 二手商品列表
  getGoodList: (params) => {
    return myHttp({
      url: "/goods/secondGoods/list",
      method: "GET",
      params,
    });
  },

  // 二手商品-通过id查询
  getGoodDetail: (params) => {
    return myHttp({
      url: "/goods/secondGoods/queryById",
      method: "GET",
      params,
    });
  },
  // 二手商品-查询机床类目
  getGoodSort: (params) => {
    return myHttp({
      url: "/goods/secondGoods/categoryList",
      method: "GET",
      params,
    });
  },

  // 轮播图列表查询
  getRotation: (params) => {
    return myHttp({
      url: "/goods/banner/list",
      method: "GET",
      params,
    });
  },
  // 商品购买意向-添加
  addBuyIntention: (params) => {
    return myHttp({
      url: "/goods/secondGoodsIntention/add",
      method: "POST",
      data: params,
    });
  },
  // ================ 地址 ================
  // 地址管理
  // 添加地址
  addPos: (params) => {
    return myHttp({
      url: "/repair/address/add",
      method: "POST",
      data: params,
    });
  },
  // 查询所有地址
  getAllPos: (params) => {
    return myHttp({
      url: "/repair/address/list",
      method: "GET",
      params,
    });
  },
  // 通过用户id查询
  getAllPosById: (params) => {
    return myHttp({
      url: "/repair/address/listByUserId",
      method: "GET",
      params,
    });
  },
  // 根据id查询某个地址
  getOnePos: (params) => {
    return myHttp({
      url: "/repair/address/queryById",
      method: "GET",
      params,
    });
  },
  // 通过id删除
  delOnePos: (params) => {
    return myHttp({
      url: "/repair/address/delById",
      method: "DELETE",
      params,
    });
  },
  // 地址管理-编辑
  editOnePos: (params) => {
    return myHttp({
      url: "/repair/address/edit",
      method: "POST",
      data: params,
    });
  },
  // 地址管理-分页列表查询
  getPosPaging: (params) => {
    return myHttp({
      url: "/repair/address/list",
      method: "GET",
      params,
    });
  },
  // ================ 报修 ================
  // 报修订单-添加
  addRepairOrder: (params) => {
    return myHttp({
      url: "/repair/repairOrder/add",
      method: "POST",
      data: params,
    });
  },
  // 报修订单-分页列表查询
  repairOrderList: (params) => {
    return myHttp({
      url: "/repair/repairOrder/list",
      method: "GET",
      params,
    });
  },
  // 报修订单-通过id查询
  repairOrderById: (params) => {
    return myHttp({
      url: "/repair/repairOrder/queryById",
      method: "GET",
      params,
    });
  },
  // 报修订单-取消
  cancleRepairOrder: (params) => {
    return myHttp({
      url: "/repair/repairOrder/edit",
      method: "POST",
      data: params,
    });
  },
  // 取消原因-分页列表查询
  cancleList: (params) => {
    return myHttp({
      url: "/repair/reason/queryCancelReason",
      method: "GET",
    });
  },
  // 取消原因-通过id查询
  cancleResonById: (params) => {
    return myHttp({
      url: "/repair/reason/queryById",
      method: "GET",
      params,
    });
  },
  // 报修订单-编辑-评价
  toComment: (params) => {
    return myHttp({
      url: "/repair/repairOrder/edit",
      method: "POST",
      data: params,
    });
  },
  // ================ 维保记录 ================
  // 维保记录-添加
  addRepairRecord: (params) => {
    return myHttp({
      url: "/work/workOrder/addUserWorkOrder",
      method: "POST",
      data: params,
    });
  },
  // 维保记录-分页列表查询
  getRepairRecordList: (params) => {
    return myHttp({
      url: "/work/workOrder/list",
      method: "GET",
      params,
    });
  },
  // 维保记录-通过设备id查询列表
  // /work/workOrder/listByDeviceId
  getRepairRecordById: (params) => {
    return myHttp({
      url: "/work/workOrderSummary/list",
      method: "GET",
      params,
    });
  },
  // ================ 维保提醒 ================
  // 设备维保提醒-添加
  addRepairRemind: (params) => {
    return myHttp({
      url: "/device/deviceRemind/add",
      method: "POST",
      data: params,
    });
  },
  // 维保提醒-分页列表查询
  getRepairRemindList: (params) => {
    return myHttp({
      url: "/device/deviceRemind/list",
      method: "GET",
      params,
    });
  },
  // 维保提醒-编辑
  repairRemindEdit: (params) => {
    return myHttp({
      url: "/device/deviceRemind/edit",
      method: "POST",
      data: params,
    });
  },
  // 维保提醒-通过id查询
  getRepairRemindId: (params) => {
    return myHttp({
      url: "/device/deviceRemind/queryById",
      method: "GET",
      params,
    });
  },
  // 维保提醒-通过id删除
  delRepairRemindId: (params) => {
    return myHttp({
      url: "/device/deviceRemind/delById",
      method: "GET",
      params,
    });
  },
  // ================ 设备资料 ================
  // 设备文件-添加
  addDeviceFile: (params) => {
    return myHttp({
      url: "/device/deviceFile/add",
      method: "POST",
      data: params,
    });
  },
  // 设备文件-分页列表查询
  getDeviceList: (params) => {
    return myHttp({
      url: "/device/deviceFile/list",
      method: "GET",
      params,
    });
  },
  // 设备文件-删除
  delDeviceFile: (params) => {
    return myHttp({
      url: "/device/deviceFile/delById",
      method: "DELETE",
      params,
    });
  },
  // ================ 维修意向 ================
  // 维修意向-添加
  repairIntentionApi: (params) => {
    return myHttp({
      url: "/repair/repairIntention/add",
      method: "POST",
      data: params,
    });
  },
  // ================ 标签-列表查询-问题描述列表 ================
  // 标签-列表查询
  tagList: (params) => {
    return myHttp({
      url: "/repair/tag/list",
      method: "GET",
    });
  },
  // ================ 购买意向 ================
  // 购买意向-分页列表查询
  buyIntentionList: (params) => {
    return myHttp({
      url: "/goods/secondGoodsIntention/list",
      method: "GET",
      params,
    });
  },
  // 商品购买意向-通过id查询
  byIntentionById: (params) => {
    return myHttp({
      url: "/goods/secondGoodsIntention/queryById",
      method: "GET",
      params,
    });
  },
  // ================ 用户收藏 ================
  // 用户收藏-分页列表查询
  userCollectionList: (params) => {
    return myHttp({
      url: "/user/userCollection/list",
      method: "GET",
      params,
    });
  },
  // 用户收藏-批量删除
  userCollectionDelete: (params) => {
    return myHttp({
      url: "/user/userCollection/batchDelete",
      method: "DELETE",
      params,
    });
  },
  // ================ 反馈类型 ================
  // 反馈类型-分页列表查询-所有反馈类型
  getFeedbackList: (params) => {
    return myHttp({
      url: "/personal/feedbackType/list",
      method: "GET",
      params,
    });
  },
  // 反馈-添加
  addFeedback: (params) => {
    return myHttp({
      url: "/personal/feedback/add",
      method: "POST",
      data: params,
    });
  },
  // ================ 弹窗 ================
  //意向书-添加-商务合作-机床回收
  recovery: (params) => {
    return myHttp({
      url: "/goods/intention/add",
      method: "POST",
      data: params,
    });
  },
};

export default API;
