<template>
  <div class="footer-contaner">
    <el-row class="contaner">
      <el-row class="left">
        <ul>
          <li>
            <router-link :to="{ name: 'about' }">关于我们</router-link>
          </li>
          <li class="line"></li>
          <li><router-link :to="{ name: 'connect' }">联系我们</router-link></li>
          <li class="line"></li>
          <li>
            <router-link :to="{ name: 'feedback' }">意见反馈</router-link>
          </li>
          <li class="line"></li>
          <li>
            <router-link :to="{ name: 'secret' }">隐私与政策</router-link>
          </li>
        </ul>
        <p class="line-one">
          <span class="line-left">浙ICP备2021039758号-1</span>
        </p>
        <p class="line-two">
          © CopyRight 2022杭州优服云科技有限公司 All Rights Reserved
        </p>
      </el-row>
      <el-row class="right">
        <img src="@/assets/images/home/erweima.png" alt="" />
        <!-- <p>关注小程序</p> -->
      </el-row>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "YoufuJichuangFooter",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.footer-contaner {
  width: 100%;
  height: 192px;
  box-sizing: border-box;
  background-color: #333333;
  display: flex;
}
.contaner {
  background-color: #333333;
  display: flex;
  justify-content: center;

  // padding: 44px 568px 40px 359px;
}
.left {
  margin-top: 36px;
  display: inline-block;
  width: 600px;
  // height: 118px;
  // border-right: 1px solid #484848;
  vertical-align: top;
  ul {
    width: 680px;
    cursor: pointer;
    // height: 21px;
    line-height: 21px;
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    li {
      color: #fff !important;
      float: left;
      white-space: nowrap;
    }
    .line {
      width: 1px;
      height: 12px;
      background: #484848;
      border-color: #484848;
      // background-color: #ffffff;
      margin-right: 24px;
      margin-left: 24px;
    }
  }
  .line-one {
    width: 680px;
    font-size: 14px;
    color: #fff;
    margin-top: 33px;
    white-space: nowrap;
    .line-left {
      margin-right: 36px;
    }
  }
  .line-two {
    width: 680px;
    font-size: 14px;
    color: #fff;
    margin-top: 16px;
    white-space: nowrap;
  }
}
.right {
  display: inline-block;
  margin-top: 36px;
  margin-left: 103px;
  // height: 118px;
  img {
    width: 140px;
    // height: 80px;
    // height: auto;
  }
  p {
    // margin-top: 20px;
    font-size: 16px;
    color: #99989c;
  }
}
</style>