<template>
  <div class="home-contaner">
    <!-- 头部 -->
    <Header></Header>
    <!-- Nav -->
    <Nav></Nav>
    <!-- 轮播图 -->
    <el-row class="lunbo">
      <el-row class="contaner">
        <el-carousel class="swiper" arrow="never" loop autoplay>
          <el-carousel-item v-for="(item, index) in rotationList" :key="index">
            <img :src="item.imgUrl" alt="" />
          </el-carousel-item>
        </el-carousel>
      </el-row>
    </el-row>

    <!-- 内容区 -->
    <el-row class="contaner-area">
      <el-row class="contaner">
        <!-- 我的设备  技术中心  。。。。 -->
        <el-row class="nav-box" type="flex" justify="space-between">
          <router-link :to="{ name: 'datascreen' }">
            <el-row class="item" type="flex" justify="center" align="middle">
              <img src="@/assets/images/home/nav1.png" alt="" class="animate__animated animate__backInLeft" />
              <p class="menu_item">数据大屏</p>
            </el-row>
          </router-link>

          <router-link :to="{ name: 'device' }">
            <el-row class="item" type="flex" justify="center" align="middle">
              <img src="@/assets/images/home/nav1.png" alt="" class="animate__animated animate__backInDown" />
              <p class="menu_item">我的设备</p>
            </el-row>
          </router-link>

          <router-link :to="{ name: 'tecnology' }">
            <el-row class="item" type="flex" justify="center" align="middle">
              <img src="@/assets/images/home/nav2.png" alt="" class="animate__animated animate__backInDown" />
              <p class="menu_item">技术中心</p>
            </el-row>
          </router-link>

          <router-link :to="{ name: 'shopping' }">
            <el-row class="item" type="flex" justify="center" align="middle">
              <img src="@/assets/images/home/nav3.png" alt="" class="animate__animated animate__backInDown" />
              <p class="menu_item">二手机床</p>
            </el-row>
          </router-link>

          <router-link :to="{ name: 'market' }">
            <el-row class="item" type="flex" justify="center" align="middle">
              <img src="@/assets/images/home/nav4.png" alt=""  class="animate__animated animate__backInRight" />
              <p class="menu_item">配件商城</p>
            </el-row>
          </router-link>

          <!-- <el-row class="item" type="flex" justify="center" align="middle">
            <div @click="toPeijian">
              <a href="#">
                <img src="@/assets/images/home/nav4.png" alt="" />
                <p>配件商城</p>
              </a>
            </div>
          </el-row> -->
        </el-row>

        <!-- 机床商城 -->
        <el-row class="shop-box">
          <!-- 头部 -->
          <el-row class="header" type="flex" justify="space-between">
            <el-row type="flex" align="middle" class="title">
              <img src="@/assets/images/home/around.png" alt="" />
              <span>二手机床商城</span>
            </el-row>
            <el-row type="flex" align="middle" class="more">
              <span>
                <router-link :to="{ name: 'shopping' }">更多</router-link>
              </span>
              <i class="el-icon-arrow-right"></i>
            </el-row>
          </el-row>
          <!-- 展示区 -->
          <el-row class="good">
            <template v-if="goodList.length">
              <el-row
                class="item"
                v-for="(item, index) in goodList"
                :key="index"
              >
                <router-link :to="{ name: 'detail', query: { id: item.id } }">
                  <img :src="item.mainImg" alt="" />
                  <p class="text">{{ item.goodsName }}</p>
                  <p class="price">
                    {{
                      item.isNegotiable
                        ? "面议"
                        : "¥" + item.showPrice.toFixed(2)
                    }}
                  </p>
                </router-link>
              </el-row>
            </template>
            <el-empty v-else description="暂无数据"></el-empty>
          </el-row>
        </el-row>
        <!-- 维保案例 -->
        <div class="case-area" id="case-box-id">
          <!-- 头部 -->
          <el-row class="header" type="flex" justify="space-between">
            <el-row type="flex" align="middle" class="title">
              <img src="@/assets/images/home/around.png" alt="" />
              <span>技术中心</span>
            </el-row>
            <el-row type="flex" align="middle" class="more">
              <router-link :to="{ name: 'tecnology' }">
                <span>更多</span>
                <i class="el-icon-arrow-right"></i>
              </router-link>
            </el-row>
          </el-row>

          <!-- 案例 -->
          <div class="case">
            <!-- 无视频 -->
            <!-- <router-link :to="{ name: 'case-detail' }">
              <el-row class="item" type="flex" align="middle">
                <img src="@/assets/images/home/case.png" alt="" />
                <el-row class="contant-box">
                  <el-row class="box">
                    <p class="contant">
                      Liquidity
                      Services将联合Manheim共同负责IVECO依维柯卡车澳大利亚工厂关厂处置项目
                    </p>
                    <p class="date">2019-07-20 12:23</p>
                  </el-row>
                </el-row>
              </el-row>
            </router-link> -->

            <!-- 有视频 -->
            <template v-if="atriceList.length">
              <div
                style="display: inline-block"
                v-for="(item, index) in atriceList"
                :key="index"
                @click="handleArticleClick(item)"
              >
                <el-row class="item" type="flex" align="middle">
                  <div class="img-box">
                    <img :src="item.mainImg" alt="" />
                    <div class="banner-box" v-if="item.releaseType === 1">
                      <img
                        class="banner-img"
                        src="@/assets/images/home/video.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <el-row class="contant-box">
                    <el-row class="box">
                      <p class="contant">
                        {{ item.essayTitle }}
                      </p>
                      <p class="date">{{ item.examineTime }}</p>
                    </el-row>
                  </el-row>
                </el-row>
              </div>
            </template>
            <el-empty
              style="width: 100%"
              v-else
              description="暂无数据"
            ></el-empty>
          </div>
          <div
            v-if="atriceList.length >= total && atriceList.length"
            style="text-align: center; margin-top: 20px"
            class="c9"
          >
            无更多数据
          </div>
        </div>
        <!-- 加载更多 -->
        <el-row
          v-if="loading"
          class="loading-more"
          v-loading="loading"
          element-loading-text="正在加载"
          element-loading-background="transparent"
          element-loading-target="loading-more"
          element-loading-spinner="el-icon-loading"
        >
        </el-row>
      </el-row>
    </el-row>
    <!-- 尾部 -->
    <Footer></Footer>
    <!-- 右侧保修 客服 -->
    <el-row class="fix-right">
      <el-row class="item1">
        <!-- <router-link :to="{ name: 'nologinrepair' }"> -->
        <el-button type="text" @click="toRepair">
          <img src="@/assets/images/home/tool.png" alt="" />
          <p>报修</p>
        </el-button>
        <!-- </router-link> -->
      </el-row>
      <!-- 客服 -->
      <el-row class="item2">
        <el-button type="text" @click="assderVisible = true">
          <img src="@/assets/images/home/kefu.png" alt="" />
          <p>联系客服</p>
        </el-button>
      </el-row>
      <!-- 联系客服 -->
    </el-row>
    <!-- 弹窗 start -->
    <el-dialog
      :visible.sync="assderVisible"
      width="600px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">联系平台</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p>
          <img src="@/assets/images/home/phone.png" alt="" />
          <span>{{ showConText.officialTel }} </span>
        </p>
        <p>
          <img src="@/assets/images/home/weixin.png" alt="" />
          <span>{{ showConText.officialWeChat }} </span>
        </p>
        <p>
          <img src="@/assets/images/home/qq.png" alt="" />
          <span>{{ showConText.officialQq }} </span>
        </p>
        <p>
          <img src="@/assets/images/home/email.png" alt="" />
          <span>{{ showConText.officialMail }} </span>
        </p>
      </div>
    </el-dialog>
    <!-- 弹窗结束 -->
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";

export default {
  components: {
    Header,
    Nav,
    Footer,
  },
  data() {
    return {
      assderVisible: false, //客服弹窗
      goodList: [],
      images: [
        require("@/assets/images/home/lunbo1.png"),
        require("@/assets/images/home/lunbo1.png"),
        require("@/assets/images/home/lunbo1.png"),
        require("@/assets/images/home/lunbo1.png"),
      ], //轮播图片
      rotationList: [], //轮播信息列表

      // 底部数据相关
      loading: true, //是否正在加载
      total: 0,
      pageNo: 1,
      pageSize: 10,
      atriceList: [],

      showConText: {}, //联系信息
      url: "", //配件商城
    };
  },

  mounted() {},
  created() {
    // 监听滚动
    window.addEventListener("scroll", () => {
      if (localStorage.getItem("scrollPagePath") === "/home") {
        this.onScroll();
      }
    });
    // 二手商品的列表
    this.getGoodList();

    // 轮播
    this.getRotation();

    // 底部的列表
    this.getArticeList();

    //根据分组查询系统配置
    this.getConfig();

    // 获取配件商城地址
    // this.toPeijian();
  },

methods: {
    // 配件商城
    toPeijian() {
      this.$router.push("/market");

      console.log("配件商城");
      // this.$API
      //   .peiJian()
      //   .then((res) => {
      //     if (res.code == 1) {
      //       // this.showConText = res.data;
      //       this.url = res.data;
      //     } else {
      //       this.$message.info(res.msg);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    // 去报修
    toRepair() {
      this.$router.push("/nologinrepair");

      // if (!this.$store.state.token) {
      //   this.$router.push("/nologinrepair");
      // } else {
      //   this.$router.push("/device");
      // }
    },
    //根据分组查询系统配置
    getConfig() {
      let paramData = {
        configGroup: "tellUs",
      };
      this.$API
        .getConfig(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 二手商品列表
    getGoodList() {
      let params = {
        pageNo: 1,
        pageSize: 8,
      };
      this.$API
        .getGoodList(params)
        .then((res) => {
          if (res.code == 1) {
            this.goodList = res.data.records;
          } else {
            // this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //弹窗方法
    assderHandle() {
      this.assderVisible = false;
    },
    // 轮播图列表查询
    getRotation() {
      let params = {
        bannerType: 1,
        pageNo: 1,
        pageSize: 100,
      };
      this.$API
        .getRotation(params)
        .then((res) => {
          if (res.code == 1) {
            this.rotationList = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 底部的列表
    getArticeList() {
      this.loading = true;
      let paramData = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      this.$API
        .getArticeListApi(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.total = res.data.total;
            this.atriceList = this.atriceList.concat(res.data.records);
          } else {
            this.$message.info(res.msg);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    // 底部的列表项点击跳转操作
    handleArticleClick(item) {
      // 发布类型（1-视频类型 2-文章类型）
      let urlObj = {
        1: "/case-video?id=" + item.id,
        2: "/case-detail?id=" + item.id,
      };
      this.$router.push(urlObj[item.releaseType]);
    },

    // 页面滚动处理函数
    onScroll() {
      // 底部的列表盒子
      let caseBoxDom = document.querySelector("#case-box-id");
      let { height: caseBoxHeight, top: caseBoxTop } =
        caseBoxDom.getBoundingClientRect();
      let outerHeight = document.documentElement.clientHeight;

      if (
        caseBoxHeight + caseBoxTop <= outerHeight + 100 &&
        caseBoxHeight + caseBoxTop > outerHeight
      ) {
        if (this.atriceList.length < this.total) {
          this.pageNo += 1;
          this.getArticeList();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-contaner{
  background-color: #f8f8f8;
  .menu_item{
    font-size: 16px;
  }
  .item:hover .menu_item{
    font-weight: 600;
  }
}
.el-message-box__wrapper {
  background-color: #000000 !important;
}
// 轮播
.lunbo {
  margin-top: 12px;
  .contaner {
    width: 100% !important;
    .swiper{
      width: 1200px;
      display: block;
      margin: 0 auto;
      border-radius: 6px;
    }
  }
  .el-carousel__item {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
// 内容区
.contaner-area {
  width: 100%;
  height: auto;
  padding-bottom: 40px;
  background-color: #f8f8f8;
  .contaner {
    background-color: #f8f8f8;
  }
}
// 我的设备  技术中心
.nav-box {
  width: 1200px;
  margin-top: 20px;
  color: #333;
  font-size: 20px;
  .item {
    flex-direction: column;
    width: 240px;
    height: 120px;
    // background: #ffffff;
    border-radius: 16px 16px 16px 16px;
    cursor: pointer;
    transition: box-shadow 0.03s;
    &:hover {
      // box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.12);
    }
    img {
      width: 70px;
      height: 70px;
      margin-bottom: 12px;
    }
  }
}
// 机床商城
.shop-box {
  margin-top: 68px;
  .title {
    cursor: pointer;
    height: 26px;
    img {
      width: 6px;
      height: 24px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
      color: #000;
    }
  }
  .more {
    cursor: pointer;
    color: #333;
    font-size: 14px;
  }
  // 展示区
  .good {
    margin-top: 24px;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 24px;
    background-color: #ffffff;
    .item {
      display: inline-block;
      width: 273px;
      height: 334px;
      background: #ffffff;
      border: 1px solid #f0f0f0;
      margin-right: 20px;
      margin-bottom: 25px;
      cursor: pointer;
      transition: box-shadow 0.03s;
      &:hover {
        box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.12);
      }
      &:nth-child(4n) {
        margin-right: 0px;
      }
      img {
        width: 273px;
        height: 214px;
      }
      .text {
        height: 50px;
        margin-top: 12px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
        margin-left: 12px;
        margin-right: 5px;
        @include dot(2);
      }
      .price {
        margin-top: 16px;
        font-size: 20px;
        color: #ed1b23;
        line-height: 25px;
        margin-left: 12px;
        margin-right: 5px;
      }
    }
  }
}
// 维保案例
.case-area {
  margin-top: 40px;
  .title {
    cursor: pointer;
    height: 26px;
    img {
      width: 6px;
      height: 24px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
      color: #000;
    }
  }
  .more {
    cursor: pointer;
    color: #333;
    font-size: 14px;
  }
  .case {
    background-color: #ffffff;
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    padding-bottom: 0px;
    margin-top: 24px;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      // 有视频case
      .img-box {
        width: 120px;
        height: 120px;
        margin-right: 20px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        // border-radius: 5px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 0px 22px #C0C0C0 !important;
        .banner-box {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 120px;
          height: 120px;
          background: #000000;
          border-radius: 16px 16px 16px 16px;
          opacity: 0.3;
          display: flex;
          justify-content: center;
          align-items: center;
          .banner-img {
            width: 32px;
            height: 32px;
            margin-right: 0px !important;
            // border-radius: 5px;
          }
        }
      }
      width: 540px;
      display: inline-block;
      margin-bottom: 32px;
      cursor: pointer;
      transition: box-shadow 0.03s;
      &:hover img{
        width: 200px;
        height: 200px;
      }
      &:nth-child(2n + 1) {
        margin-right: 32px;
      }

      &:nth-child(2n) {
        margin-right: 40px;
      }
      img {
        width: 120px;
        height: 120px;
        margin-right: 20px;
        vertical-align: top;
        transition: all 0.2s;
      }
      .contant-box {
        display: inline-block;
      }
      .box {
        width: 400px;
        height: 120px;
        .contant {
          font-size: 16px;
          font-weight: 600;
          color: #333;
          padding-top: 10px;
          @include dot(2);
        }
        .date {
          margin-top: 35px;
          position: absolute;
          bottom: 10px;
          font-size: 14px;
          color: #666;
        }
      }
    }
  }
}
// 加载更多
.loading-more {
  width: 100%;
  height: 174px;
  margin: 0 auto;
}
// 右侧保修 客服
.fix-right {
  cursor: pointer;
  width: 88px;
  height: 180px;
  border: 1px solid #ebebeb;
  position: fixed;
  z-index: 1000;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  .item1 {
    background-color: #ffffff;
  }
  .item2 {
    background-color: #ed1b23;
  }
  .item1,
  .item2 {
    height: 90px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 9px;
    img {
      width: 24px;
      height: 24px;
    }
    p {
      color: #757575;
      font-size: 14px;
      margin-top: 12px;
    }
  }
  .item2 p {
    color: #ffffff;
  }
}
// 自定义弹窗样式
.assderTc {
  display: flex;
  align-items: center;
  .title {
    padding-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    img {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 32px;
      right: 32px;
    }
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog {
    border-radius: 0 !important;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog {
    border-radius: 10px;
  }
  .ja-c {
    height: 311px;
    box-sizing: border-box;
    padding-left: 214px;
    padding-top: 52px;
    p {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      img {
        width: 30px;
        height: 30px;
      }
      span {
        font-size: 14px;
        color: #333333;
        margin-left: 16px;
      }
    }
  }

  .btnad {
    width: 112px;
    height: 40px;
    background: rgba(246, 247, 249, 1);
    border: 1px solid #ecedef;
    opacity: 1;
    border-radius: 20px;
  }
  .btnsad {
    color: #333;
    width: 112px;
    height: 40px;
    background: rgba(255, 195, 44, 1);
    opacity: 1;
    border-radius: 20px;
  }
}
</style>
