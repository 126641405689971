"use strict";
import Vue from "vue";
import Vuex from "vuex";
import { localSetItem, localRemoveItem } from "@/config/local";
import axios from "axios";
import API from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		// ! process.env.NODE_ENV == "development" 不行 因为打包测试的时候 这个值为生产模式
		ISDEV: 1, // 是否开发模式 1 是; 0 否;
		userInfo: {}, // 用户信息
		token: "", //token
		formData: {}
	},
	getters: {
		// 获取 token
		token(state) {
			if (state.userInfo && Object.keys(state.userInfo).length > 0) {
				return state.userInfo.token;
			} else {
				return "";
			}
		},
	},
	mutations: {
		setInfo(state, payload) {
			state.userInfo = payload;
		},
		setToken(state, payload) {
			state.token = payload;
		},

		// 清除用户信息
		// 清除的时候，本地存储的token，vuex中的token，都清理掉，就像新用户访问网站一样
		clearInfo(state) {
			localStorage.removeItem("user-token");
			state.token = "";
			state.userInfo = {};
		},


		clearToken(state, payload) {
			localStorage.removeItem("user-token");
			state.token = payload;
		},
		// 登录成功
		login(state, payload) {
			// 登录成功将userInfo和token存入storage中
			state.userInfo = payload;
			localSetItem("userInfo", payload);
			localSetItem("token", payload.token);
		},
		// 退出登录
		logout(state, payload) {
			// 退出登录，清除storage中的userInfo和token
			state.userInfo = {};
			localRemoveItem("userInfo");
			localRemoveItem("token");
		},
	},
	actions: {
		// 根据token获取用户信息
		async getUserInfo(ctx) {
			try {
				const apiRes = await API.getInfo();
				if (apiRes.code == 1) {
					ctx.commit("setInfo", apiRes.data);
				} else {
					throw Error(apiRes.msg);
				}
			} catch (err) {
				throw Error(err);
			}

			// 这种写法，放到async函数中，无法吧错误抛出，所以我用await
			// API.getInfo().then(res => {
			// 	if (res.code == 1) {
			// 		ctx.commit("setInfo", res.data);
			// 	} else {
			// 		throw Error(res.msg)
			// 	}
			// })
			// return new Promise((rs, rj) => {

			// });
		}

		// 登录接口调用
		// toLogin(ctx, payload) {
		// 	console.log("来到actions了", API)
		// 	return new Promise((resolve, reject) => {
		// 		API.login().then(res => {
		// 			ctx.commit('setInfo', res.data.userInfo);
		// 			ctx.commit('setToken', res.data.token);
		// 			resolve()
		// 		})
		// 	})
		// }
	},
	modules: {},
});
