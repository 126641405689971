<template>
  <div class="header-contaner">
    <el-row class="contaner">
      <el-row type="flex" align="middle" class="header">
        <p>Hi~欢迎来到优服工业机床健康</p>
      </el-row>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.header-contaner {
  // width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #000000;
  display: none;
  p {
    font-size: 12px;
    color: #fff;
  }
  
  .contaner{
    background-color: #000000;
  }
}
</style>